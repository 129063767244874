import {SocketProvider} from "@hooks";
import {createGenerateClassName, jssPreset, StylesProvider} from "@material-ui/styles";
import AppContext from "app/RouteContext";
import {create} from "jss";
import jssPluginExtend from "jss-plugin-extend";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import {ConfirmProvider} from "material-ui-confirm";
import {Suspense} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {LocalizeProvider} from "react-localize-redux";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import {SWRConfig} from "swr";
import {FuseTheme} from "../@fuse";
import FuseLoading from "../@fuse/components/FuseLoading/FuseLoading";
import history from "../@history";
import {confirmDefaultOptions} from "../utils/variables";
import AppContent from "./AppContent";
import {AuthProvider} from "./auth/AuthProvider";
import "../i18n";
import routes from "./configs/routesConfig";
import store from "./store";
import i18n from "../i18n";
import {swrFetcher} from "@api";

const jssInsert = document.getElementById("jss-insertion-point");

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssPluginExtend()],
    insertionPoint: jssInsert || undefined,
});

const generateClassName = createGenerateClassName();

const App = () => {
    return (
        <Suspense fallback={<FuseLoading />}>
            <I18nextProvider i18n={i18n} defaultNS={["_", "errors", "profile", "login", "nav", "titles"]}>
                <Router history={history}>
                    <AppContext.Provider value={{routes}}>
                        <Provider store={store}>
                            <AuthProvider>
                                <StylesProvider jss={jss} generateClassName={generateClassName}>
                                    <LocalizeProvider>
                                        <SocketProvider>
                                            <SWRConfig
                                                value={{
                                                    refreshInterval: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? 0 : 600000,
                                                    fetcher: swrFetcher,
                                                    revalidateOnMount: false,
                                                    onErrorRetry: (error) => {
                                                        if (error.status === 400) {
                                                            //noinspection UnnecessaryReturnStatementJS
                                                            return;
                                                        }
                                                    },
                                                    dedupingInterval: 0,
                                                    revalidateOnFocus: !(!process.env.NODE_ENV || process.env.NODE_ENV === "development"),
                                                    // revalidateOnFocus: true,
                                                }}>
                                                <FuseTheme>
                                                    {/*// @ts-ignore*/}
                                                    <ConfirmProvider defaultOptions={confirmDefaultOptions}>
                                                        {/*// @ts-ignore*/}
                                                        <DndProvider backend={HTML5Backend}>
                                                            <AppContent />
                                                        </DndProvider>
                                                    </ConfirmProvider>
                                                </FuseTheme>
                                            </SWRConfig>
                                        </SocketProvider>
                                    </LocalizeProvider>
                                </StylesProvider>
                            </AuthProvider>
                        </Provider>
                    </AppContext.Provider>
                </Router>
            </I18nextProvider>
        </Suspense>
    );
};

if (window.Cypress) {
    // @ts-ignore cypress
    window.store = store;
}

export default App;
